<template>
  <div class="addAdministrator">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>{{formList?'添加':'修改'}}地推人员管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form
      class="demo-form-inline"
      :model="formList"
      ref="ruleForm"
    >
      <el-form-item label="" prop="userName">
        <span class="title_box">真实姓名</span>
        <el-input
        v-model.trim="formList.trueName"
        placeholder="真实姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="userName">
        <span class="title_box">联系手机</span>
        <el-input
        v-model.trim="formList.phone"
        placeholder="联系手机"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="userName">
        <span class="title_box">简介</span>
        <el-input
        class="txtarea"
         type="textarea"
        :rows="8"
        v-model.trim="formList.summary"
        placeholder="简介"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSave()">保存</el-button>
        <el-button type="primary" @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { staffListAPI,addStaffAPI,staffDetailAPI,delStaffAPI,generateQRcodeAPI } from "../../../api/groundPush";
export default {
  name: "AddAdministrator",
  data() {
    return {
      formList: {
        staffId:0,
        trueName: "",
        phone: "",
        summary:""
      },
    };
  },
  created() {
      if(this.$route.query.staffId){
        this.getDetail()
      }
  },
  methods: {
      async getDetail(){
            const { data } = await staffDetailAPI({
              staffId:this.$route.query.staffId
          })
          this.formList = data.data[0]
          console.log(data,'详细的数据')
      },
      async onSave(){
           if(this.formList.trueName.length != '' &&
           this.formList.phone.length != '' &&
           this.formList.summary.length != ''){
            await this.addStaff()
            this.$message({ message: "添加成功", type: "success" }); 
            setTimeout(() => {
                this.$router.go(-1)
            }, 500);
           }else{
            this.$message({ message: "请输入完整的数据", type: "warning" }); 
           }
      },
      addStaff(){
        const {data} = addStaffAPI(this.formList)
      },
  },
};
</script>
<style lang="less" scoped>
.addAdministrator {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  /deep/.el-form {
    margin-top: 50px;
    .el-form-item {

      .el-form-item__content {
        display: flex;
        span{
          width:180px;
        }
        .el-form-item__error {
          margin-left: 75px;
        }
        
      }
    }
    .avatar {
      display: flex;
      align-items: center;
      .el-form-item__content {
        display: flex;
        align-items: center;
        .img {
          position: relative;
          margin: 0 20px;
          height: 100px;
          width: 100px;
          img {
            height: 100px;
            width: 100px;
            border: 1px solid #c0ccda;
          }
          div {
            position: absolute;
            top: 0;
            right: 5px;
            height: 10px;
            width: 10px;
            z-index: 100;
          }
        }
        .addImg {
          display: inline-block;
          width: 100px;
          height: 100px;
          border: 1px dashed #c0ccda;
          text-align: center;
          line-height: 100px;
          cursor: pointer;
          i {
            width: 50px;
            height: 50px;
            font-size: 20px;
            color: #c0ccda;
          }
        }
        span {
          margin-left: 30px;
          color: red;
        }
        .el-button {
          margin-right: 20px;
        }
      }
    }
    .el-form-item__label {
      width: 80px;
    }
    .el-input {
      width: 400px;
      
    }
  }
}
.title_box{
    width: 150px;
    margin-left: 40px;
}
.txtarea{
  // padding-left: 24px;
  width: 400px;
}
</style>