import request from '../utils/request'
import baseUrl from './baseUrl'
//人员列表
export const staffListAPI = (data) => request({
    url: baseUrl + '/staffInfo/selectForBack',
    method: 'POST',
    data
})
//增加/修改人员
export const addStaffAPI = (data) => request({
    url: baseUrl + '/staffInfo/add',
    method: 'POST',
    data
})
//查看详情
export const staffDetailAPI = (data) => request({
    url: baseUrl + '/staffInfo/selectForId',
    method: 'POST',
    data
})
//删除人员
export const delStaffAPI = (data) => request({
    url: baseUrl + '/staffInfo/deleteById',
    method: 'POST',
    data
})
//生成小程序码
export const generateQRcodeAPI = (data) => request({
    url: baseUrl + '/staffInfo/selectErCode',
    method: 'POST',
    data
})